import React, { useEffect, useState } from "react";
import Filter, { FilterState } from "./components/Filter";
import { Button, Col, Row, Select, Typography } from "antd";
import StatementEditor from "./components/StatementEditor";
import {
  useGetStatementListQuery,
  useUpdateCaseStatementMutation,
} from "./CasesApiSlice";
import LoadingSpinner from "../../components/LoadingSpinner";
import ErrorComponent from "../../components/ErrorComponent";
import InfiniteScroll from "react-infinite-scroll-component";
import { Statement } from "./types";
import TopicSelector from "../../components/dash-components/TopicSelector";


const { Title } = Typography;

const mockGrades: FilterOption[] = [
  { value: "k", label: "Kindergarten" },
  { value: "1", label: "Grade 1" },
  { value: "2", label: "Grade 2" },
  { value: "3", label: "Grade 3" },
  { value: "4", label: "Grade 4" },
  { value: "5", label: "Grade 5" },
  { value: "6", label: "Grade 6" },
  { value: "7", label: "Grade 7" },
  { value: "8", label: "Grade 8" },
  { value: "9", label: "Grade 9" },
  { value: "10", label: "Grade 10" },
  { value: "11", label: "Grade 11" },
  { value: "12", label: "Grade 12" },
];

const mockSubjects: FilterOption[] = [
  { value: "math", label: "Mathematics" },
  { value: "science", label: "Science" },
  { value: "english", label: "English" },
  { value: "history", label: "History" },
  { value: "geography", label: "Geography" },
  { value: "physics", label: "Physics" },
  { value: "chemistry", label: "Chemistry" },
  { value: "biology", label: "Biology" },
  { value: "literature", label: "Literature" },
  { value: "computer_science", label: "Computer Science" },
  { value: "art", label: "Art" },
  { value: "music", label: "Music" },
  { value: "physical_education", label: "Physical Education" },
];

const mockChapters: FilterOption[] = [
  { value: "ch1", label: "Chapter 1: Introduction" },
  { value: "ch2", label: "Chapter 2: Fundamentals" },
  { value: "ch3", label: "Chapter 3: Advanced Concepts" },
  { value: "ch4", label: "Chapter 4: Practical Applications" },
  { value: "ch5", label: "Chapter 5: Case Studies" },
  { value: "ch6", label: "Chapter 6: Problem Solving" },
  { value: "ch7", label: "Chapter 7: Historical Context" },
  { value: "ch8", label: "Chapter 8: Modern Developments" },
  { value: "ch9", label: "Chapter 9: Future Trends" },
  { value: "ch10", label: "Chapter 10: Review and Synthesis" },
];

const mockBloomLevels: FilterOption[] = [
  { value: "remember", label: "Remember" },
  { value: "understand", label: "Understand" },
  { value: "apply", label: "Apply" },
  { value: "analyze", label: "Analyze" },
  { value: "evaluate", label: "Evaluate" },
  { value: "create", label: "Create" },
];

export interface FilterOption {
  value: string;
  label: string;
}

const CourseList = [
  { label: "Maths - 10", value: "6582caec1aff9b1973d73ffb" },
  { label: "Maths - 9", value: "664d9b94a605c413ba966ece" },
  { label: "Maths - 8", value: "664d9b14a605c413ba966eb1" },
  { label: "Maths - 7", value: "664d9aaea605c413ba966e94" },
  { label: "Maths - 6", value: "664d99f2a605c413ba966e78" }, //need to fetch from id
  { label: "Maths 2024 - 6", value: "66c62154bd96bd15443265c4" },
];

const Reviewer: React.FC = () => {
  const LIMIT = 50;
  const [items, setItems] = useState<Statement[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [loadingData, setLoadingData] = useState(false);
  const [selectedCourseId, setSelectedCourseId] = useState<string>();
  const [page, setPage] = useState(1);

  const { data, isLoading, error } = useGetStatementListQuery({
    limit:LIMIT,
    skip: (page - 1) * LIMIT,
    topicId: selectedCourseId,
  });

  const [updateStatement, { isLoading: updatingStatement }] =
    useUpdateCaseStatementMutation();

  useEffect(() => {
    if (selectedCourseId) {
      setItems([]);
      setHasMore(true);
      setPage(1);
      setSelectedIndex(-1);
      
      
    }
  }, [selectedCourseId]);

  useEffect(() => {
    if (data?.data) {
      if (data?.data.length === 0) {
        setHasMore(false);
        return;
      }

      const iterableData = data.data;
      console.log(iterableData)
      setItems((items) => [...items, ...iterableData]);
      setSelectedIndex((prevIndex) => prevIndex + 1);
    }
  }, [data?.data]);

  const moveToNextIndex = async () => {
    if (selectedIndex < items.length - 1) {
      setSelectedIndex((prevIndex) => prevIndex + 1);
    } else {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const moveToPreviousIndex = () => {
    setSelectedIndex((prevIndex) => prevIndex - 1);
  };

  const saveUpdatedStatement = async (statement: string) => {
    const result = await updateStatement({
      id: items[selectedIndex].id,
      data: statement,
    });

    if (result.error) {
      console.error("Failed to update statement", result.error);
    } else if (result.data) {
      console.debug("Statement updated");
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <ErrorComponent message={error as string} />;
  }

  if (items.length === 0 && !isLoading) {
    return <div>No data found</div>;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        position: "relative",
        paddingTop: 12,
      }}
    >
      <div style={{ paddingLeft: 24, paddingRight: 24, paddingTop: 12 }}>
      {/* <TopicSelector classes={classData} onChange={updateFilter} /> */}
        <Select
          style={{ width: 160, position: "relative" }}
          placeholder="Select Course"
          onChange={(value) => setSelectedCourseId(value)}
          value={selectedCourseId}
        >
          {CourseList.map((item, index) => (
            <Select.Option key={index} value={item.value}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
        {/* <Filter
          onFilterChange={handleFilterChange}
          grades={mockGrades}
          subjects={mockSubjects}
          chapters={mockChapters}
          bloomLevels={mockBloomLevels}
        /> */}
      </div>
      <div
        style={{
          flex: 1,
          overflow: "auto",
        }}
      >
        <StatementEditor
          initialData={items[selectedIndex].statements[0] as string}
          onSave={saveUpdatedStatement}
        />
        <div
          style={{
            position: "absolute",
            bottom: 8,
            left: 0,
            right: 0,
            height: 48,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0px 24px",
          }}
        >
          <Button onClick={moveToPreviousIndex} disabled={selectedIndex === 0}>
            Previous
          </Button>
          <Button
            onClick={moveToNextIndex}
            disabled={hasMore === false || loadingData}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Reviewer;

// /**
//  * 
//  * {
//     "_id" : ObjectId("663490cc96470760530113a7"),
//     "statements" : [
//         "A farm is experimenting with different hormone treatments to improve the health and productivity of their livestock. They focus on enhancing growth rates, reproductive success, and overall well-being of the animals."
//     ]
// }
//  */











// import React, { useEffect, useState } from "react";
// import { Button, Select } from "antd";
// import {
//   useGetStatementListQuery,
//   useUpdateCaseStatementMutation,
// } from "./CasesApiSlice";
// import LoadingSpinner from "../../components/LoadingSpinner";
// import ErrorComponent from "../../components/ErrorComponent";
// import StatementEditor from "./components/StatementEditor";
// import { Statement } from "./types";

// const { Option } = Select;

// const CourseList = [
//   { label: "Maths - 10", value: "6582caec1aff9b1973d73ffb" },
//   { label: "Maths - 9", value: "664d9b94a605c413ba966ece" },
//   { label: "Maths - 8", value: "664d9b14a605c413ba966eb1" },
//   { label: "Maths - 7", value: "664d9aaea605c413ba966e94" },
//   { label: "Maths - 6", value: "664d99f2a605c413ba966e78" },
// ];

// const Reviewer: React.FC = () => {
//   const LIMIT = 50;
//   const [items, setItems] = useState<Statement[]>([]);
//   const [hasMore, setHasMore] = useState(true);
//   const [selectedIndex, setSelectedIndex] = useState<number>(0);
//   const [selectedCourseId, setSelectedCourseId] = useState<string | undefined>(undefined);
//   const [page, setPage] = useState(1);

//   const { data, isLoading, error } = useGetStatementListQuery({
//     limit: LIMIT,
//     skip: (page - 1) * LIMIT,
//     topicId: selectedCourseId, // Fetch based on selected course ID
//   });

//   const [updateStatement] = useUpdateCaseStatementMutation();

//   // Effect to reset items when a course is selected
//   useEffect(() => {
//     if (selectedCourseId) {
//       setItems([]); // Reset items when course changes
//       setPage(1); // Reset pagination
//       setHasMore(true); // Reset load more
//       setSelectedIndex(0); // Reset the selected index
//     }
//   }, [selectedCourseId]);
// console.log(selectedCourseId)
//   // Effect to handle data updates
//   useEffect(() => {
//     if (data?.data) {
//       if (data.data.length < LIMIT) {
//         setHasMore(false); // No more data to load
//       }
//       setItems((prevItems) => [...prevItems, ...data.data]); // Append new data
//     }
//   }, [data]);

//   const moveToNextIndex = () => {
//     if (selectedIndex < items.length - 1) {
//       setSelectedIndex((prevIndex) => prevIndex + 1);
//     } else if (hasMore) {
//       setPage((prevPage) => prevPage + 1); // Load next page
//     }
//   };

//   const moveToPreviousIndex = () => {
//     setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
//   };

//   const saveUpdatedStatement = async (statement: string) => {
//     const currentStatement = items[selectedIndex];
//     if (currentStatement) {
//       await updateStatement({ id: currentStatement.id, data: statement });
//     }
//   };

//   if (isLoading && items.length === 0) return <LoadingSpinner />; // Show spinner on initial load
//   if (error) return <ErrorComponent message={error as string} />;
//   if (items.length === 0 && !isLoading) return <div>No data found</div>;

//   return (
//     <div style={{ paddingTop: 12 }}>
//       <Select
//         style={{ width: 160 }}
//         placeholder="Select Course"
//         onChange={(value) => setSelectedCourseId(value)}
//         value={selectedCourseId}
//       >
//         {CourseList.map((course) => (
//           <Option key={course.value} value={course.value}>
//             {course.label}
//           </Option>
//         ))}
//       </Select>

//       <div style={{ flex: 1, overflow: "auto", paddingTop: 12 }}>
//         {items[selectedIndex] && (
//           <StatementEditor
//             initialData={items[selectedIndex].statements[0] as string}
//             onSave={saveUpdatedStatement}
//           />
//         )}
//       </div>

//       <div style={{ display: "flex", justifyContent: "space-between", paddingTop: 16 }}>
//         <Button onClick={moveToPreviousIndex} disabled={selectedIndex === 0}>
//           Previous
//         </Button>
//         <Button onClick={moveToNextIndex} disabled={!hasMore && selectedIndex >= items.length - 1}>
//           Next
//         </Button>
//       </div>
//     </div>
//   );
// };

// export default Reviewer;
