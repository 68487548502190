export enum AppName {
  Neo = "neo",
  Gyaani = "gyaani",
}

// export const HOST = "http://localhost:3000";





let HOST = "https://api.prod.bheem.ai";
let ADMIN_PORTAL_URL = "http://dash.neoapp.in";

export { ADMIN_PORTAL_URL, HOST };