import React, { useState, useEffect } from "react";
import { Select, Space } from "antd";
import { SelectValue } from "antd/es/select";

interface Concept {
  id: string;
  name: string;
}

interface Subtopic {
  id: string;
  name: string;
  concepts: Concept[];
}

interface Topic {
  id: string;
  name: string;
  subtopics: Subtopic[];
}

export interface ClassData {
  id: string;
  name: string;
  topics: Topic[];
}

interface MultiLevelSelectorProps {
  classes: ClassData[];
  allowConceptLevelSelection?: boolean;
  onChange: (selection: {
    classId: string | null;
    topicId: string | null;
    subtopicId: string | null;
    conceptIds: string[];
  }) => void;
}

const MultiLevelSelector: React.FC<MultiLevelSelectorProps> = ({
  classes,
  allowConceptLevelSelection = false,
  onChange,
}) => {
  const [selectedClass, setSelectedClass] = useState<string | null>(null);
  const [selectedTopic, setSelectedTopic] = useState<string | null>(null);
  const [selectedSubtopic, setSelectedSubtopic] = useState<string | null>(null);
  const [selectedConcepts, setSelectedConcepts] = useState<string[]>([]);

  useEffect(() => {
    onChange({
      classId: selectedClass,
      topicId: selectedTopic,
      subtopicId: selectedSubtopic,
      conceptIds: selectedConcepts,
    });
  }, [selectedClass, selectedTopic, selectedSubtopic, selectedConcepts]);

  const handleClassChange = (value: SelectValue) => {
    setSelectedClass(value as string);
    setSelectedTopic(null);
    setSelectedSubtopic(null);
    setSelectedConcepts([]);
  };

  const handleTopicChange = (value: SelectValue) => {
    setSelectedTopic(value as string);
    setSelectedSubtopic(null);
    setSelectedConcepts([]);
  };

  const handleSubtopicChange = (value: SelectValue) => {
    setSelectedSubtopic(value as string);
    setSelectedConcepts([]);
  };

  const handleConceptChange = (value: SelectValue) => {
    setSelectedConcepts(value as string[]);
  };

  const selectedClassObj = classes.find((c) => c.id === selectedClass);
  const selectedTopicObj = selectedClassObj?.topics.find(
    (t) => t.id === selectedTopic
  );
  const selectedSubtopicObj = selectedTopicObj?.subtopics.find(
    (s) => s.id === selectedSubtopic
  );

  const getConceptLabel = (conceptIds: string[]) => {
    if (conceptIds.length === 0) return "Select Concepts";
    if (conceptIds.length === 1)
      return (
        selectedSubtopicObj?.concepts.find((c) => c.id === conceptIds[0])
          ?.name || ""
      );
    return `${conceptIds.length} concepts selected`;
  };

  return (
    <Space size="large">
      <Select
        style={{ width: 200 }}
        placeholder="Select Class"
        value={selectedClass}
        onChange={handleClassChange}
      >
        {classes.map((c) => (
          <Select.Option key={c.id} value={c.id}>
            {c.name}
          </Select.Option>
        ))}
      </Select>

      <Select
        style={{ width: 200 }}
        placeholder="Select Topic"
        value={selectedTopic}
        onChange={handleTopicChange}
        disabled={!selectedClassObj}
      >
        {selectedClassObj?.topics.map((t) => (
          <Select.Option key={t.id} value={t.id}>
            {t.name}
          </Select.Option>
        ))}
      </Select>

      <Select
        style={{ width: 200 }}
        placeholder="Select Subtopic"
        value={selectedSubtopic}
        onChange={handleSubtopicChange}
        disabled={!selectedTopicObj}
      >
        {selectedTopicObj?.subtopics.map((s) => (
          <Select.Option key={s.id} value={s.id}>
            {s.name}
          </Select.Option>
        ))}
      </Select>

      {allowConceptLevelSelection && (
        <Select
          mode="multiple"
          style={{ width: 200 }}
          placeholder="Select Concepts"
          value={selectedConcepts}
          onChange={handleConceptChange}
          disabled={!selectedSubtopicObj}
          maxTagCount={0}
          maxTagPlaceholder={() => getConceptLabel(selectedConcepts)}
        >
          {selectedSubtopicObj?.concepts.map((c) => (
            <Select.Option key={c.id} value={c.id}>
              {c.name}
            </Select.Option>
          ))}
        </Select>
      )}
    </Space>
  );
};

export default MultiLevelSelector;
