import { apiSlice } from "../app/api/apiSlice";

// Create competitionApi with injected endpoints
const competitionApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createCompetitionActivity: builder.mutation({
      query: ({ competitionId, data }) => ({
        url: `/competition/${competitionId}/activity`, 
        method: "POST",
        body:  data , 
      }),
    }),
    createCompetitionActivityProfession: builder.mutation({
      query: ({ competitionId, competitionProfessionData }) => ({
        url: `/competition/${competitionId}/activity/profession`,
        method: "POST",
        body: competitionProfessionData,
      }),
    }),
    createFoundationalActivity: builder.mutation({
      query: ({ competitionId, foundationalData }) => ({
        url: `/competition/${competitionId}/activity/foundational`,
        method: "POST",
        body: foundationalData,
      }),
    }),
    createActivity: builder.mutation({
      query: ({ data }) => ({
        url: `/activity/foundational`,  
        method: "POST",
        body: data,  
      }),
    }),
    notifyUsers: builder.mutation({
      query: (activityId) => ({
        url: `/activity/${activityId}/notifyUsers`,
        method: "POST",
      }),
    }),
    fetchCompetitions: builder.query({
      query: () => ({
        url: '/competitions', // Adjust the endpoint based on your API
        method: 'GET',
      }),
    }),
  }),
});

// Export hooks to use in components
export const { useCreateCompetitionActivityMutation, useCreateCompetitionActivityProfessionMutation, useCreateFoundationalActivityMutation, useCreateActivityMutation, useNotifyUsersMutation, useFetchCompetitionsQuery } = competitionApi;

