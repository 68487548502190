import React from "react";
import CompetitionForm from "./CompetitionForm";
import ProfessionCompetitionForm from "./ProfessionCompetition";
import FoundationalForm from "./FoundationalForm";
import ShowSubjectByGrade from "./ShowSubjectByGrade";
import NotifyUsersDialog from "./NotifyUsersDialog";
import ActivityForm from "./AcademicWorksheetForm";
import AcademicWorksheetForm from "./AcademicWorksheetForm";


const AllModals = () => {
  return (
      <div className="flex flex-col items-start gap-2 ml-4 mt-4">
      <CompetitionForm  />
      <ProfessionCompetitionForm  />
      {/* <FoundationalForm /> */}
      {/* <ShowSubjectByGrade grade={10} /> */}
      <NotifyUsersDialog  />
      <AcademicWorksheetForm />
      
    </div>
  );
};

export default AllModals;




// import React, { useState } from "react";
// import CompetitionForm from "./CompetitionForm";
// import ProfessionCompetitionForm from "./ProfessionCompetition";
// import NotifyUsersDialog from "./NotifyUsersDialog";
// import AcademicWorksheetForm from "./AcademicWorksheetForm";
// import { MatchComponentType, MatchQuestion } from "../components/dash-components/Mtf/types";
// import { MatchTheFollowingEditor } from "../components/dash-components/Mtf/MtfEditor";
// import MatchTheFollowingViewer from "../components/dash-components/Mtf/MtfViewer";


// const dummyMatchQuestion: MatchQuestion = {
//   pairs: [
//     {
//       question: {
//         type: MatchComponentType.TEXT,
//         value: "Question 1",
//       },
//       answer: {
//         type: MatchComponentType.TEXT,
//         value: "Answer 1",
//       },
//     },
//     {
//       question: {
//         type: MatchComponentType.IMAGE,
//         value: "https://example.com/image1.jpg", // Example image URL
//       },
//       answer: {
//         type: MatchComponentType.TEXT,
//         value: "Answer 2",
//       },
//     },
//     {
//       question: {
//         type: MatchComponentType.AUDIO,
//         value: "https://example.com/audio1.mp3", // Example audio URL
//       },
//       answer: {
//         type: MatchComponentType.TEXT,
//         value: "Answer 3",
//       },
//     },
//   ],
// };

// const AllModals = () => {
//   const [editing, setEditing] = useState(false);

//   const handleEdit = () => {
//     setEditing(true);
//   };

//   const handleSave = (updatedQuestion: MatchQuestion) => {
//     console.log("Saving updated question:", updatedQuestion);
//     setEditing(false);
//   };

//   const handleCloseEditor = () => {
//     setEditing(false);
//   };

//   return (
//     <div className="flex flex-col items-start gap-2 ml-4 mt-4">
//       <CompetitionForm />
//       <ProfessionCompetitionForm />
//       <NotifyUsersDialog />
//       <AcademicWorksheetForm />

//       {editing ? (
//         <MatchTheFollowingEditor
//           uploadImage={async (file: File) => {
//             // Implement your image upload logic here
//             return "https://example.com/uploaded-image.jpg"; // Return the URL of the uploaded image
//           }}
//           uploadAudio={async (file: File) => {
//             // Implement your audio upload logic here
//             return "https://example.com/uploaded-audio.mp3"; // Return the URL of the uploaded audio
//           }}
//           onSave={handleSave}
//           data={dummyMatchQuestion}
//         />
//       ) : (
//         <MatchTheFollowingViewer
//           question={dummyMatchQuestion}
//           onEdit={handleEdit}
//         />
//       )}
//     </div>
//   );
// };

// export default AllModals;










// import React, { useState } from "react";
// import { MatchComponentType, MatchQuestion } from "../components/dash-components/Mtf/types";


// import MatchTheFollowingViewer from "../components/dash-components/Mtf/MtfViewer";
// import { MatchTheFollowingEditor } from "../components/dash-components/Mtf/MtfEditor";


// const dummyMatchQuestion: MatchQuestion = {
//   en_question: [
//     {
//       type: MatchComponentType.TEXT,
//       value: "Match the following:",
//     },
//   ],
//   pairs: [
//     {
//       question: {
//         type: MatchComponentType.TEXT,
//         value: "Apple",
//       },
//       answer: {
//         type: MatchComponentType.TEXT,
//         value: "Fruit",
//       },
//     },
//     {
//       question: {
//         type: MatchComponentType.IMAGE,
//         value: "https://example.com/image1.jpg",
//       },
//       answer: {
//         type: MatchComponentType.TEXT,
//         value: "Image 1 Answer",
//       },
//     },
//   ],
// };

// const AllModals = () => {
//   const [editing, setEditing] = useState(false);

//   const handleEdit = () => {
//     setEditing(true);
//   };

//   const handleSave = (updatedQuestion: MatchQuestion) => {
//     console.log("Saving updated question:", updatedQuestion);
//     setEditing(false);
//   };

//   return (
//     <div className="flex flex-col items-start gap-2 ml-4 mt-4">
//       {editing ? (
//         <MatchTheFollowingEditor
//           uploadImage={async (file: File) => "https://example.com/uploaded-image.jpg"}
//           uploadAudio={async (file: File) => "https://example.com/uploaded-audio.mp3"}
//           onSave={handleSave}
//           data={dummyMatchQuestion}
//         />
//       ) : (
//         <MatchTheFollowingViewer question={dummyMatchQuestion} onEdit={handleEdit} />
//       )}
//     </div>
//   );
// };

// export default AllModals;
