import { Button, Card, Col, Radio, Row, Space } from "antd";
import { OptionComponent, QuestionComponent } from "./McqEditor";
import { IMCQQuestion } from "./types";
import { useEffect, useState } from "react";

export interface McqViewerProps {
  question: IMCQQuestion;
  onEdit?: () => void;
}

export default function McqViewer({ question, onEdit }: McqViewerProps) {
  return (
    <Card
      extra={
        <Space>
          {onEdit && (
            <Button onClick={onEdit} type="primary">
              Edit
            </Button>
          )}
        </Space>
      }
      title="Question"
      style={{ marginBottom: "20px" }}
      bodyStyle={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      {question.en_question.map((component, idx) => (
        <QuestionComponent {...component} key={idx} />
      ))}
      <Radio.Group
        style={{ width: "100%" }}
        value={question.en_options.find((option) => option.isCorrect)?.text}
      >
        <Space direction="vertical">
          {question.en_options.map((option, idx) => {
            return <OptionComponent option={option} key={idx} />;
          })}
        </Space>
      </Radio.Group>
    </Card>
  );
}