export enum Questiontype {
  MCQ = "mcq",
}

export enum QuestionComponentType {
  TEXT = "TEXT",
  IMAGE = "IMAGE",
  INLINE_IMAGES = "INLINE_IMAGES",
  AUDIO = "AUDIO",
}

export enum OptionType {
  TEXT = "TEXT",
  IMAGE = "IMAGE",
  AUDIO = "AUDIO",
}

export interface QuestionComponentBase {}

export interface OptionBase {
  isCorrect: boolean;
}

export interface TextComponent extends QuestionComponentBase {
  type: QuestionComponentType.TEXT;
  value: string;
}

export interface ImageComponent extends QuestionComponentBase {
  type: QuestionComponentType.IMAGE;
  value: string;
}

export interface InlineImagesComponent extends QuestionComponentBase {
  type: QuestionComponentType.INLINE_IMAGES;
  value: string[];
}

export interface AudioComponent extends QuestionComponentBase {
  type: QuestionComponentType.AUDIO;
  value: string;
}

export type Component =
  | TextComponent
  | ImageComponent
  | InlineImagesComponent
  | AudioComponent;

export interface TextOption extends OptionBase {
  type: OptionType.TEXT;
  text: string;
}

export interface ImageOption extends OptionBase {
  type: OptionType.IMAGE;
  text: string;
}

export interface AudioOption extends OptionBase {
  type: OptionType.AUDIO;
  text: string;
}

export type MCQOption = TextOption | ImageOption | AudioOption;

export interface IMCQQuestion {
  id?: string;
  en_question: Component[];
  en_options: MCQOption[];
  bloomLevel?: number;
}
