import React from "react";
import { Layout, Menu } from "antd";
import {
  QuestionOutlined,
  HomeOutlined,
  BookOutlined,
  IssuesCloseOutlined,
  UsergroupAddOutlined,
  StarFilled,
  AliwangwangOutlined,
  BankOutlined,
  CopyOutlined,
  UserOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useThemeColors } from "../../../app/theme/useThemeColors";

const { Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

export default function SideNav() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPathKey = location.pathname.split("/")[2] || "home";
  const colors = useThemeColors();
  const items: MenuItem[] = [
    {
      key: "home",
      label: "Home",
      icon: <HomeOutlined />,
      onClick: () => navigate("home"),
    },
    // {
    //   key: "classes",
    //   label: "Classes",
    //   icon: <BookOutlined />,
    //   onClick: () => navigate("classes"),
    // },
    {
      key: "statements",
      label: "Case based questions",
      icon: <CopyOutlined />,
      // navigate to questions
      onClick: () => navigate("statements"),
    },
    {
      key: "questions",
      label: "Questions",
      icon: <QuestionOutlined />,
      // navigate to questions
      onClick: () => navigate("questions"),
    },
    {
      key: "Quiz",
      label: "Quiz",
      icon: <UserOutlined />,
      onClick: () => navigate("quiz"),
    },
    // {
    //   key: "skills",
    //   label: "Skills",
    //   icon: <AliwangwangOutlined />,
    //   onClick: () => navigate("quests"),
    // },
    // {
    //   key: "issues",
    //   label: "Issues",
    //   icon: <IssuesCloseOutlined />,
    //   onClick: () => navigate("issues"),
    // },
    {
      key: "schools",
      label: "Schools",
      icon: <BankOutlined />,
      onClick: () => navigate("schools"),
    },
    // {
    //   key: "leads",
    //   label: "Superstars",
    //   icon: <StarFilled style={{ color: colors.primaryColor }} />,
    //   onClick: () => navigate("leads"),
    // },
    {
      key: "users",
      label: "Users",
      icon: <UsergroupAddOutlined />,
      onClick: () => navigate("users"),
    },
  ];
  return (
    <Sider style={{ background: "#fff" }} collapsible>
      <Menu
        mode="inline"
        defaultSelectedKeys={[currentPathKey]}
        selectedKeys={[currentPathKey]}
        items={items}
      />
    </Sider>
  );
}
